import { Side, InputGroup, Button, Group } from '@revolut/ui-kit'
import React, { useEffect, ChangeEvent } from 'react'
import { ticketsRequests } from '@src/api/tickets'
import Form from '@src/features/Form/Form'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { useLapeContext } from '@src/features/Form/LapeForm'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import { TicketInterface } from '@src/interfaces/tickets'
import SideBar from '@components/SideBar/SideBar'
import LapeSingleCheckbox from '@components/Inputs/LapeFields/LapeSingleCheckbox'

interface BugReportSideProps {
  open: boolean
  onClose: () => void
  onSuccess?: () => void
}

interface BugReportFormProps {
  onClose: () => void
  onSuccess?: () => void
}

const getExtraInfo = () => {
  return `UserAgent: ${window.navigator.userAgent}`
}

const BugReportForm = ({ onClose, onSuccess }: BugReportFormProps) => {
  const { values } = useLapeContext<TicketInterface>()

  useEffect(() => {
    if (!values.url) {
      let url = new URL(window.location.href)
      url.searchParams.delete('help')
      values.url = url.href
    }
  }, [window.location.href])

  useEffect(() => {
    values.extra_info = getExtraInfo()
    values.is_extra_info_shared = true
    values.is_communication_allowed = true
  }, [])

  const handleExtraInfoChange = (e: ChangeEvent<HTMLInputElement>) => {
    values.is_extra_info_shared = e.currentTarget.checked
    values.extra_info = e.currentTarget.checked ? getExtraInfo() : undefined
  }

  return (
    <>
      <InputGroup>
        <LapeNewInput
          required
          name="title"
          label="Tell us what’s wrong"
          message="Provide short summary of issue you are experiencing"
        />
        <LapeNewTextArea
          name="text"
          label="Help us to understand the issue better"
          message="Provide detailed information about your issue"
          rows={3}
        />
        <LapeNewInput name="url" label="URL" />
        <Group>
          <LapeSingleCheckbox
            name="is_communication_allowed"
            label="We may contact you via email for updates"
          />
          <LapeSingleCheckbox
            name="is_extra_info_shared"
            label="Share activity logs in Revolut People"
            description="This helps us to investigate an issue"
            onChange={handleExtraInfoChange}
          />
        </Group>
      </InputGroup>

      <Side.Actions horizontal>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <NewSaveButtonWithPopup
          useValidator
          onAfterSubmit={() => {
            onSuccess?.()
            onClose()
          }}
          successText="Thank you, issue was submitted"
          successDescription="You can find your requests in Help Center"
        />
      </Side.Actions>
    </>
  )
}

export const BugReportSide = ({ open, onClose, onSuccess }: BugReportSideProps) => {
  return (
    <SideBar
      useLayout
      isOpen={open}
      onClose={onClose}
      variant="wide"
      title="Report a bug"
    >
      <Form api={ticketsRequests} isExistingForm={false} disableLocalStorageCaching>
        <BugReportForm onClose={onClose} onSuccess={onSuccess} />
      </Form>
    </SideBar>
  )
}
