import React from 'react'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import {
  Subheader,
  Status,
  Sticky,
  ProgressSteps,
  ProgressStep,
  Widget,
  Group,
  Box,
  BREAKPOINTS,
} from '@revolut/ui-kit'
import { PageWrapper } from '@components/Page/Page'
import { useGetTicket } from '@src/api/tickets'
import { useParams } from 'react-router'
import { TwoColumnsLayout } from '@src/pages/EmployeeProfile/Layout/common/TwoColumnsLayout'
import styled from 'styled-components'
import { FormPreview } from '@components/FormPreview/FormPreview'
import { TicketFullInterface, ticketStatuses } from '@src/interfaces/tickets'
import upperFirst from 'lodash/upperFirst'
import { formatDateTime } from '@src/utils/format'
import { statusColorMap } from '@src/pages/Tickets/common'

const StyledSticky = styled(Sticky)`
  max-height: calc(100vh - 80px);
  overflow: scroll;
`

export const Ticket = () => {
  const { id } = useParams<{ id?: string }>()
  const { data: ticket } = useGetTicket(id)

  if (!ticket) {
    return null
  }

  return (
    <PageWrapper>
      <PageHeader
        title={ticket.title}
        subtitle={
          <Status
            useIcon="16/StatusActive"
            iconColor={statusColorMap[ticket.current_status.status]}
            color={statusColorMap[ticket.current_status.status]}
          >
            {upperFirst(ticket.current_status.status)}
          </Status>
        }
        backUrl={ROUTES.TICKETS.ALL}
      />

      <TwoColumnsLayout
        left={
          <Widget>
            <FormPreview<TicketFullInterface>
              data={ticket}
              customHeader={() => (
                <Box px="s-16">
                  <Subheader variant="nested">
                    <Subheader.Title>Details</Subheader.Title>
                  </Subheader>
                </Box>
              )}
            >
              <Group>
                <FormPreview.Item field="url" title="URL" />
                <FormPreview.Details field="text" title="Description" />
              </Group>
            </FormPreview>
          </Widget>
        }
        right={
          <StyledSticky top={70}>
            <Widget>
              <Box px="s-16">
                <Subheader variant="nested">
                  <Subheader.Title>Report status</Subheader.Title>
                </Subheader>
              </Box>

              <ProgressSteps variant="vertical-compact">
                {ticketStatuses.map(status => {
                  const ticketStatus = ticket.status_list.find(s => s.status === status)
                  return (
                    <ProgressStep
                      state={ticketStatus ? 'done' : undefined}
                      key={status}
                      indicatorColor={ticketStatus ? 'blue' : undefined}
                    >
                      <ProgressStep.Title>{upperFirst(status)}</ProgressStep.Title>
                      <ProgressStep.Description>
                        {ticketStatus
                          ? formatDateTime(ticketStatus.creation_date_time)
                          : '-'}
                      </ProgressStep.Description>
                    </ProgressStep>
                  )
                })}
              </ProgressSteps>
            </Widget>
          </StyledSticky>
        }
        leftMaxWidth={BREAKPOINTS.md}
        rightMaxWidth={350}
      />
    </PageWrapper>
  )
}
